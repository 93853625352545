import { instance } from './index';
var DOCUSEAL_URL = '/widget/docuseal';
var getTemplates = function () {
    return instance({
        method: 'GET',
        url: "".concat(DOCUSEAL_URL, "/templates")
    });
};
export default {
    getTemplates: getTemplates
};
