import React, { useEffect, useState, useMemo, memo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import api from '../../../api';
import { useInsurance } from '../../../context/Insurance';
import shade from '../../../utils/shade';
import CardLoader from '../../CardLoader';
import PolicyPurchaseConfirmation from '../../common/PolicyPurchaseConfirmation';
import Tooltip from '../../common/Tooltip';
import Modal from '../../Modal/index';
import { RotateLoader } from 'react-spinners';
import PolicyForm from './Screens/PolicyForm';
import useConfigStore from '../../../stores/configStore';
import useWidgetStore, { CurrentTabEnum } from '../../../stores/widgetStore';
import useCustomerStore from '../../../stores/customerStore';
import useSaasPlatformStore from '../../../stores/saasPlatformStore';
import useProductStore from '../../../stores/productStore';
import useLatestQuotesWithPolicies from '../../../hooks/useLatestQuotesWithPolicies';
import useLatestQuote from '../../../hooks/useLatestQuote';
import useLatestPolicy from '../../../hooks/useLatestPolicy';
import useErrorStore from '../../../stores/errorStore';
import useErrorHandledMutation from '../../../hooks/useErrorHandledMutation';
import ErrorScreen from './ErrorScreen';
import QuoteLoading from './QuoteLoading';
import LimitFlow from './LimitFlow.tsx';
import PolicyLoading from './PolicyLoading';
import PolicyListing from './PolicyListing';
import useToken from '../../../hooks/useToken';
import getStyles from '../../../utils/getStyles';
import { parseISO, addYears, format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import PolicyStepsHeader from '../../common/PolicyStepsHeader';
import VerifiedIcon from '../../icons/VerifiedIcon';
import QuotePending from './Screens/QuotePending';
import QuoteRejected from './Screens/QuoteRejected';
import PolicyPending from './Screens/PolicyPending';
import SelectApplicationDetails from './Screens/SelectApplicationDetails';
import LimitPolicySelection from './LimitPolicySelection.tsx';

const getQuoteInfo = (quoteDetails) => {
  switch (quoteDetails?.partnerIdentifier) {
    case 'tigerLabs':
      return {
        quoteId: quoteDetails?.partnerData?.rateQuote?.number,
        insuranceProvider: 'TigerLabs',
        quoteExpense: (
          quoteDetails?.total -
          Number(quoteDetails?.partnerData?.rateQuote?.iptAmount)
        )?.toFixed(2),
        quoteIPT: quoteDetails?.partnerData?.rateQuote?.iptAmount,
        quoteTotalAmount: quoteDetails?.total?.toFixed(2),
      };
    case 'opal':
      return {
        quoteId: `${quoteDetails?.partnerData?.identifier?.split('-')[0]}-${
          quoteDetails?.partnerData?.identifier?.split('-')[0]
        }`,
        insuranceProvider: 'Opal',
        quoteExpense: (
          quoteDetails?.partnerData?.premium?.gross -
          0.2 * quoteDetails?.partnerData?.premium?.gross
        )?.toFixed(2),
        quoteIPT: (
          (quoteDetails?.partnerData?.premium?.gross / 100) *
          20
        )?.toFixed(2),
        quoteTotalAmount: quoteDetails?.partnerData?.premium?.gross?.toFixed(2),
      };
    default:
      return {
        quoteId: `${quoteDetails?.id?.split('-')[0]}-${
          quoteDetails?.id?.split('-')[1]
        }`,
        insuranceProvider: 'WTW',
        quoteExpense: (
          Number(quoteDetails?.total) -
          0.2 * Number(quoteDetails?.total)
        )?.toFixed(2),
        quoteIPT: ((Number(quoteDetails?.total) / 100) * 20)?.toFixed(2),
        quoteTotalAmount: quoteDetails?.total?.toFixed(2),
      };
  }
};

const handleQuoteWebsockes = (quoteId, refetchCustomer) => {
  const quoteWebsocket = new WebSocket(
    `${process.env.REACT_APP_QUOTE_WEBSOCKET_URL}?quoteId=${quoteId}`,
  );

  quoteWebsocket.onmessage = (event) => {
    refetchCustomer();
  };
};

const handlePolicyWebsockes = (policyId, refetchCustomer) => {
  const policyWebsocket = new WebSocket(
    `${process.env.REACT_APP_POLICY_WEBSOCKET_URL}?policyId=${policyId}`,
  );

  policyWebsocket.onmessage = (event) => {
    refetchCustomer();
  };
};

const Policy = memo(({ scrollableElementRef }) => {
  const {
    selectedQuote,
    setSelectedQuote,
    isFormActive,
    modalOpen,
    setModalOpen,
    setCurrentTab,
    setSelectedPolicy,
  } = useWidgetStore();
  const [purchaseConfirmation, setPurchaseConfirmation] = useState(false);
  const [restartRequest, setRestartRequest] = useState(false);
  const { keys, style } = useConfigStore();
  const { primaryColor, logo, theme } = style;
  const { widgetType, token } = keys;
  const {
    extCustomerName: customerName,
    extCustomerID: externalID,
    setExternalCustomer,
    applicationFormData,
    ...extCustomer
  } = useCustomerStore();
  const {
    applicationDataId,
    setFormActive,
    createQuoteSubmitted,
    setAnswers,
    setApplicationDataId,
  } = useWidgetStore();
  const { policyPageHeading, policyPageText, brokerFormLink } =
    useSaasPlatformStore();
  const { setProduct } = useProductStore();
  const { error, setError } = useErrorStore();
  const { errorHandledMutation } = useErrorHandledMutation();
  const latestQuotesWithPolicies = useLatestQuotesWithPolicies(true);
  const latestPolicyWithoutFilter = useLatestPolicy();
  const { refreshToken } = useToken();

  const { latestQuote, latestPolicy } = useMemo(() => {
    const latestQuote =
      latestQuotesWithPolicies.find((q) => q.id === selectedQuote) || null;
    const latestPolicy = latestQuote?.policy || null;

    return { latestQuote, latestPolicy };
  }, [latestQuotesWithPolicies, selectedQuote]);
  const queryClient = useQueryClient();

  const customer = queryClient.getQueryState(['customer'])?.data;

  const createKaynaHash = useMutation(api.auth.generateTempToken, {
    onSuccess: (resp) => {
      if (brokerFormLink) {
        window.open(
          `${
            brokerFormLink?.trim()?.endsWith('/')
              ? `${brokerFormLink?.slice(0, -1)}?kaynaToken=${
                  resp?.data?.data?.token
                }&extCustomerId=${externalID}`
              : `${brokerFormLink}?kaynaToken=${resp?.data?.data?.token}&extCustomerId=${externalID}`
          }`,
        );
        setModalOpen(false);
        setCurrentTab(CurrentTabEnum.POLICY);
      }
    },
  });

  const handleCreateKaynaHash = (applicationFormId) => {
    errorHandledMutation(() =>
      createKaynaHash.mutateAsync({
        token: token,
        applicationFormId: applicationFormId,
      }),
    );
  };

  const createQuote = useMutation(api.quote.create, {
    retry: 3,
    onSuccess: (resp) => {
      if (widgetType == 'broker') {
        handleCreateKaynaHash(resp.data.quote.externalId);
      } else {
        queryClient.invalidateQueries(['customer']);
      }
    },
  });

  const handleCreatePolicy = useMutation(api.policy.create, {
    retry: 3,
    onSuccess: (response) => {
      queryClient.invalidateQueries(['customer']);
      if (response?.data?.data?.insuranceStatus == 'active') {
        setSelectedPolicy({
          policyId: response?.data?.data?.id,
          externalId: response?.data?.data?.externalId,
        });
        setCurrentTab(CurrentTabEnum.POLICY_INFO_LAST_STEP);
      }
    },
  });

  const createQuoteMutation = (items, productId, quoteExternalRef) => {
    return errorHandledMutation(() =>
      createQuote.mutateAsync({
        customerID: customer?.data?.data?.id,
        productId: productId,
        items: items ? items : extCustomer.items,
        saasId: keys.platformId,
        externalID: customer?.data?.data?.externalId,
        quoteExternalId: quoteExternalRef,
      }),
    );
  };

  // ---------- deprecated ----------
  // const createCustomer = useMutation(api.customer.create, {
  //   onSuccess: async (customer) => {
  //     setExternalCustomer({
  //       customerId: customer?.data?.data?.id,
  //     });

  //     await refreshToken();

  //     if (keys.widgetType === 'broker') {
  //       createQuoteMutation(
  //         {
  //           requiredParams: applicationFormData,
  //           otherParams: {},
  //         },
  //         customer?.data?.data?.id,
  //       );
  //     }
  //   },
  //   onError: (err) => {
  //     setError({ error: err?.message });
  //   },
  // });

  // ---------- deprecated ----------
  // const generatePolicyDoc = useMutation(api.policy.generatePolicyDoc, {
  //   retry: 3,
  //   onSuccess: async (customer) => {
  //     queryClient.invalidateQueries(['customer']);
  //   },
  //   onError: (err) => {
  //     setError({ error: err?.message });
  //   },
  // });

  const purchaseQuoteMutation = () => {
    return errorHandledMutation(() =>
      handleCreatePolicy.mutateAsync({
        quoteId: latestQuote?.id,
        customerID: customer.data.data.id,
        customerName,
        externalID,
        insuranceStatus: 'active',
        paymentStatus: 'active',
        cycle: 'yearly',
        widgetFlow: latestQuote?.partnerIdentifier
          ? latestQuote?.partnerIdentifier
          : null,
      }),
    );
  };

  const updateTab = (e, number) => {
    e.preventDefault();
    setCurrentTab(number);
  };

  useEffect(() => {
    if (latestQuote && latestQuote.status == 'pending') {
      handleQuoteWebsockes(latestQuote.id, () =>
        queryClient.invalidateQueries(['customer']),
      );
    }
  }, [latestQuote]);

  useEffect(() => {
    if (selectedQuote && !modalOpen) {
      const isPart = latestQuotesWithPolicies?.some(
        (q) => q.id === selectedQuote,
      );

      if (!isPart || Object.keys(applicationFormData).length > 1) {
        setSelectedQuote(null);
      }
    }
  }, [latestQuotesWithPolicies, selectedQuote, modalOpen]);

  useEffect(() => {
    const policyKeys = Object.keys(applicationFormData);
    if (policyKeys.length === 1 && latestQuotesWithPolicies.length === 1) {
      setSelectedQuote(latestQuotesWithPolicies[0].id);
    }
  }, [latestQuotesWithPolicies, modalOpen]);

  useEffect(() => {
    if (latestPolicy && latestPolicy.insuranceStatus == 'pending') {
      handlePolicyWebsockes(latestPolicy.id, () => {
        queryClient.invalidateQueries(['customer']).then(() => {
          if (modalOpen) {
            setCurrentTab(CurrentTabEnum.POLICY_INFO);
          }
        });
      });
    }
  }, [latestPolicy]);

  useEffect(() => {
    if (!modalOpen) {
      setFormActive(false);
    }
  }, [modalOpen]);

  const startPurchaseHandler = () => {
    setModalOpen(true);
    setPurchaseConfirmation(true);
  };

  const selectPolicyHandler = (quote) => {
    setSelectedQuote(quote.id);
    startPurchaseHandler();

    const answers = applicationFormData?.[quote.externalId]?.applicationDetails;

    setProduct(quote.product);
    setAnswers(answers);
    setApplicationDataId(quote.externalId);
    setFormActive(true);

    if (quote.status === 'bound' || quote.status === 'MT Draft') {
      setSelectedPolicy({
        policyId: quote.policy.id,
        externalId: quote?.externalId,
      });
      setCurrentTab(CurrentTabEnum.POLICY_INFO);
    }
  };

  const restartHandler = (val) => {
    setRestartRequest(val);

    if (latestQuote) {
      const answers =
        applicationFormData?.[latestQuote.externalId]?.applicationDetails;

      setProduct(latestQuote.product);
      setAnswers(answers);
      setApplicationDataId(latestQuote.externalId);
    }
  };

  const widgetFlow = useMemo(() => {
    const productId = applicationFormData[applicationDataId]?.productId;
    const product = customer?.data?.data?.SaasPlatform?.Products?.find(
      (p) => p.id === productId,
    );

    return product?.Underwriter?.widgetFlow;
  }, [applicationFormData, applicationDataId, customer]);

  if (createQuoteSubmitted) {
    const isLimit = latestQuote?.product?.Underwriter?.widgetFlow === 'limit';

    return (
      <QuoteLoading
        showAnimated
        textHeading={
          isLimit ? 'Preparing your proposal form please wait' : undefined
        }
        justifyCenter
      />
    );
  }

  if (
    !customer?.data?.data ||
    queryClient.getQueryState(['customer'])?.status === 'loading' ||
    (!brokerFormLink && widgetType == 'broker' && !error)
  ) {
    return <QuoteLoading />;
  }

  if (createQuote.isLoading || createKaynaHash.isLoading) {
    return <QuoteLoading showAnimated />;
  }
  if (handleCreatePolicy.isLoading) {
    return <QuoteLoading />;
  }

  if (error) return <ErrorScreen />;

  if (
    latestQuotesWithPolicies.length > 0 &&
    typeof selectedQuote !== 'string' &&
    !modalOpen
  ) {
    return (
      <PolicyListing
        onSelectPolicy={selectPolicyHandler}
        onNewPolicy={startPurchaseHandler}
      />
    );
  }

  if (
    latestQuote &&
    !latestPolicy &&
    latestQuote.product.Underwriter.widgetFlow === 'limit' &&
    latestQuote.status === 'pending' &&
    latestQuote.partnerData?.shareableApplication &&
    modalOpen
  ) {
    return <LimitFlow latestQuote={latestQuote} />;
  }

  return (
    <div className="pb-5 my-auto sm:mx-4 realtive">
      {modalOpen && latestQuote && latestQuote?.status == 'complete' && (
        <div className="sticky top-0 left-0 right-0 w-full bg-white">
          <PolicyStepsHeader
            steps={[
              {
                stepText: 'Your Details',
                isStepActive: false,
                isStepCompleted: true,
              },
              ...(widgetFlow === 'limit'
                ? [
                    {
                      stepText: 'More Questions',
                      isStepActive: false,
                      isStepCompleted: false,
                    },
                  ]
                : []),
              {
                stepText: 'Coverages',
                isStepActive: latestQuote && !latestPolicy ? true : false,
                isStepCompleted: latestQuote && latestPolicy,
              },
              {
                stepText: 'Your Policy',
                isStepActive: latestQuote && latestPolicy,
                isStepCompleted: false,
              },
            ]}
            nextButtonText={
              widgetType == 'broker'
                ? 'Go to dashboard'
                : latestQuote && !latestPolicy
                ? 'Purchase Policy'
                : null
            }
            handleNext={() =>
              widgetType == 'broker'
                ? handleCreateKaynaHash(latestQuote.externalId)
                : purchaseQuoteMutation()
            }
            handleClose={() => {
              setModalOpen(false);
              setSelectedQuote(null);
            }}
          />
        </div>
      )}

      {!restartRequest && latestPolicyWithoutFilter && latestPolicyWithoutFilter.insuranceStatus == 'inactive' ? (
        <QuoteRejected
          headingText="Oops! It seems that the policy has been expired/cancelled"
          contentText="You can generate a new policy by clicking restart below"
          setRestartRequest={restartHandler}
        />
      ) : (
        latestQuote !== undefined &&
        !Boolean(latestPolicy) &&
        (latestQuote?.id &&
        latestQuote?.status == 'rejected' &&
        !restartRequest ? (
          <QuoteRejected setRestartRequest={restartHandler} />
        ) : latestQuote?.status == 'pending' && !restartRequest ? (
          <QuotePending />
        ) : modalOpen &&
          latestQuote?.id &&
          latestQuote?.status == 'complete' ? (
          <PolicyPurchaseConfirmation
            quoteId={getQuoteInfo(latestQuote).quoteId}
            insuranceProvider={getQuoteInfo(latestQuote).insuranceProvider}
            quoteExpense={getQuoteInfo(latestQuote).quoteExpense}
            quoteIPT={getQuoteInfo(latestQuote).quoteIPT}
            quoteTotalAmount={getQuoteInfo(latestQuote).quoteTotalAmount}
          />
        ) : brokerFormLink && latestQuote && widgetType == 'broker' ? (
          <div
            className={`rounded-3xl mx-2 mt-30 px-12 bg-backgroundColor my-2 flex flex-col h-full justify-center text-textColor`}
          >
            <div className="flex flex-col items-center justify-center">
              <VerifiedIcon />
              <span className="text-2xl font-bold text-center w-full mt-5 text-[#1F2751]">
                Buy your policy now
              </span>
            </div>

            <button
              className={`rounded-2xl bg-primary ${
                theme == 'night' ? 'rippleNight' : 'ripple'
              } text-primary font-bold mt-4 mx-auto tracking-lighter px-8 py-4`}
              style={{
                backgroundColor:
                  theme == 'night'
                    ? shade(primaryColor, -0.7)
                    : shade(primaryColor, 0.9),
              }}
              onClick={() => {
                setModalOpen(true);
                setPurchaseConfirmation(true);
              }}
            >
              Quote Details
            </button>
          </div>
        ) : (latestQuote?.cerityQuoteId &&
            latestQuote?.quoteQuestions &&
            queryClient.getQueryState(['customer'])?.status !== 'loading') ||
          (queryClient.getQueryState(['customer'])?.status !== 'loading' &&
            (!latestQuote || restartRequest)) ? (
          isFormActive ? (
            <PolicyForm
              scrollableElementRef={scrollableElementRef}
              setRestartRequest={setRestartRequest}
              setSelectedQuote={setSelectedQuote}
              handleCreateKaynaHash={handleCreateKaynaHash}
            />
          ) : (
            <SelectApplicationDetails
              createQuoteMutation={createQuoteMutation}
              isLoading={createQuote.isLoading}
            />
          )
        ) : (
          <div
            className={`rounded-3xl mx-2 mt-30 px-12 bg-backgroundColor my-2 flex flex-col h-full justify-center text-textColor`}
          >
            <div className="flex flex-col items-center justify-center">
              <div className="mb-5">
                <VerifiedIcon />
              </div>
              <span className="text-2xl font-bold text-center w-full text-[#1F2751]">
                Buy your policy now
              </span>
            </div>

            <button
              className={`rounded-2xl bg-primary ${
                theme == 'night' ? 'rippleNight' : 'ripple'
              } text-primary font-bold mt-4 mx-auto tracking-lighter px-8 py-4`}
              style={{
                backgroundColor:
                  theme == 'night'
                    ? shade(primaryColor, -0.7)
                    : shade(primaryColor, 0.9),
              }}
              onClick={startPurchaseHandler}
            >
              Purchase Policy
            </button>
          </div>
        ))
      )}

      {latestPolicy &&
        (latestPolicy?.insuranceStatus == 'inactive' ? (
          <QuoteRejected
            headingText="Oops! It seems that the policy has been cancelled or expired"
            contentText=" "
            buttonText="Generate New Policy"
          />
        ) : latestPolicy?.insuranceStatus == 'pending' ? (
          <PolicyPending />
        ) : (
          <div className="rounded-3xl mx-2 mt-30 px-2 xl:px-10 bg-backgroundColor my-2 flex flex-col h-full justify-center text-textColor">
            <div className="flex flex-col items-center justify-center">
              <VerifiedIcon />
              <span className="text-xl text-center w-full mt-5 text-[#202851]">
                Policy Number:
              </span>
              <span className="text-2xl font-bold text-center w-full mt-2 text-[#1F2751]">
                {latestPolicy.partnerIdentifier === 'limit'
                  ? latestPolicy.partnerData.id
                  : `${latestPolicy?.id?.split('-')[0]}-${
                      latestPolicy?.id?.split('-')[1]
                    }`}
              </span>

              <p className="text-xs font-bold mt-1 max-w-[288px]">
                Please be aware that binding and/or policy documents may take up
                to 5 business days to arrive.
              </p>

              <span className="flex items-center justify-center w-full mt-3.5">
                <span className="w-2 h-2 rounded-full bg-[#42D58E]" />
                <span className="ml-2 text-[16px] text-[#202851]">
                  Active until{' '}
                  {latestPolicy?.partnerData == 'tigerLabs'
                    ? format(
                        addYears(
                          parseISO(
                            latestPolicy?.partnerData?.policy?.createdAt,
                          ),
                          1,
                        ),
                        'MM/dd/yyyy',
                      )
                    : latestPolicy?.partnerIdentifier == 'opal'
                    ? format(
                        parseISO(
                          latestPolicy?.partnerData?.policy?.dates?.expiry,
                        ),
                        'MM/dd/yyyy',
                      )
                    : latestPolicy?.createdAt
                    ? format(
                        addYears(new Date(latestPolicy?.createdAt), 1),
                        'MM/dd/yyyy',
                      )
                    : 'Loading...'}
                </span>
              </span>
            </div>

            <div className="flex flex-col lg:flex-row gap-y-2 gap-x-3 items-center justify-center mt-5">
              {latestPolicy.partnerIdentifier !== 'limit' && (
                <div className="w-full lg:w-1/2">
                  <button
                    className="w-full h-16 rounded-2xl border-[1px] border-primary m-auto text-primary font-bold tracking-lighter hover:bg-primary hover:text-white px-2"
                    onClick={(e) => {
                      updateTab(e, CurrentTabEnum.COVERAGES);
                      setModalOpen(true);
                    }}
                  >
                    Coverages
                  </button>
                </div>
              )}
              <div className="w-full lg:w-1/2">
                <button
                  className="w-full h-16 rounded-2xl border-[1px] border-primary m-auto text-primary font-bold tracking-lighter hover:bg-primary hover:text-white px-2"
                  onClick={(e) => {
                    updateTab(e, CurrentTabEnum.POLICY_INFO);
                    setModalOpen(true);
                  }}
                >
                  Policy Details
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
});

export default Policy;
