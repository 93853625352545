import React from 'react';
import File from '../../icons/File';
var SigningTutorial = function (_a) {
    var progress = _a.progress, guideHeading = _a.guideHeading, guideText = _a.guideText, guideImageUrl = _a.guideImageUrl, onNext = _a.onNext, onPrev = _a.onPrev, onEnd = _a.onEnd, isLast = _a.isLast, isStart = _a.isStart;
    if (isStart) {
        return (React.createElement("div", { className: "flex flex-col items-center justify-center h-full" },
            React.createElement("div", { className: "flex items-center justify-center mb-4 h-[72px] w-[72px] bg-[#F8FAFE] rounded-[20px] text-[#707EAE]" },
                React.createElement(File, null)),
            React.createElement("h3", { className: "text-[32px] text-[#252846] font-bold mb-4" }, "Document Signing"),
            React.createElement("p", { className: "text-lg text-[#1F2751] mb-12" }, "You are required to sign this document by the carrier before they start with the policy bind requested."),
            React.createElement("div", { className: "flex gap-6" },
                React.createElement("button", { className: "h-12 rounded-xl text-[#707EAE] border border-[#C8CEE3] px-6 font-semibold", onClick: onNext }, "Watch The Tutorial"),
                React.createElement("button", { className: "h-12 rounded-xl bg-primary font-semibold text-white px-6", onClick: onEnd }, "Sign the Document"))));
    }
    else {
        return (React.createElement(React.Fragment, null,
            React.createElement("p", { className: "font-bold leading-6 mb-2 text-[#1F2751]" }, progress),
            React.createElement("h3", { className: "text-[28px] font-bold mb-2 text-[#252846]" }, guideHeading),
            React.createElement("p", { className: "text-[#252846] mb-[26px] max-w-[1002px] mx-auto" }, guideText),
            React.createElement("div", { className: "h-[440px] aspect-[5/4] mb-2 mx-auto" },
                React.createElement("img", { className: "object-cover h-full w-full", src: guideImageUrl })),
            React.createElement("div", { className: "flex justify-between" },
                React.createElement("div", null,
                    React.createElement("button", { className: "h-12 rounded-xl text-[#707EAE] border border-[#C8CEE3] px-6 font-semibold", onClick: onPrev }, "Previous")),
                React.createElement("div", null,
                    React.createElement("button", { className: "h-12 rounded-xl bg-primary font-semibold text-white px-6", onClick: onNext }, "Continue")))));
    }
};
export default SigningTutorial;
